import React from "react"

import Layout from "../components/layout"
import styles from "./cgu-cgv.module.scss"

const Cgu = () => {
  return (
    <Layout>
      <main className={styles.mainContainer}>
        <article>
          <h1>CGU / CGV</h1>
          <p>
            Nos offres de produits et de prix sont valables aussi longtemps
            qu'elles sont visibles sur le Site, sauf opérations spéciales dont
            la durée de validité est spécifiée sur le Site. L'internaute est
            invité à consulter la disponibilité des articles vendus sur la page
            d'information de chaque produit. En cas de commande d'un article qui
            se révélerait indisponible, wistibike.com se réserve le droit
            d'annuler la commande, sous réserve de prévenir l'Internaute. En cas
            de commande de plusieurs produits, si l'un des produits se révèle
            finalement indisponible, wistibike.com informera alors l'internaute
            de l’impossibilité d’envoi de l’article indisponible. Le reste de la
            commande sera traité et expédié au client dans les délais annoncés
            par l’email d’information sur son suivi de commande. L'internaute
            peut également opter pour l'annulation totale de sa commande, sous
            réserve d'en informer au préalable le service clients wistibike.com
            par téléphone ou email.
            <br />
            <br />
            Les prix affichés par défaut sur le site sont indiqués en euros et
            toutes charges comprises applicables en France Métropolitaine +
            Corse. La TVA est appliquée au taux en vigueur au moment de la
            passation de la commande. Les prix des produits affichés sur
            wistibike.com s'entendent hors frais de port, prix de certains
            emballages ou encore prix d'autres services optionnels, souscrits
            par l'internaute, qui restent en sus. Ces derniers seront portés à
            la connaissance de l'internaute sur l'écran récapitulatif avant
            validation définitive de la commande.
            <br />
            <br />
            L'internaute peut régler ses achats par Carte Bancaire, avec une
            carte Visa, Carte Bleue, Mastercard, une E-Carte bleue, en 3Xou 4X
            par carte bancaire ou son compte Paypal. L'internaute peut également
            procéder au règlement de ses achats via carte cadeau Wistibike.
            Wistibike.com garantie la sécurité du paiement par carte bancaire en
            utilisant le système de cryptage du protocole SSL (Secure Socket
            Layer).
            <br />
            <br />
            Les produits ne sont livrables qu’en France Métropolitaine et Corse
            à l'adresse de livraison que l'internaute aura renseignée sur sa
            commande. L’internaute peut également opter pour une livraison
            auprès du Point Relais de son choix lors du passage de la commande
            sur le Site.
          </p>
        </article>
      </main>
    </Layout>
  )
}

export default Cgu
